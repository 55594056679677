.product-thumb .product-thumb__cta {
  line-height: 16px !important;
}

.site-nav .menu__item--lvl-1 {
  &:nth-child(4) a,
  &:nth-child(3) .menu__item--lvl-2:nth-child(2) span {
    display: block;
    height: 40px;
    line-height: 20px;
  }
}

.site-utils__item.site-utils__item--email {
  right: 1%;
  .site-utils__link {
    letter-spacing: 1px;
  }
}

.site-utils__inner {
  padding-left: 8px !important;
}
